<template>
  <div class="skill-select-container">
    <v-autocomplete
      :placeholder="'Select a skill'"
      :color="colors.PRIMARY"
      :items="remainingSkills()"
      :item-text="'name'"
      :item-value="'name'"
      :error-messages="inputErrorMessage"
      :loading="isLoadingOptions"
      :no-data-text="optionsEmptyText"
      v-on:change="skillSelected"
      v-model="selectedSkill"
    ></v-autocomplete>
  </div>
</template>

<script>
import colors from "@/colors";
import skillDataAccess from "../../../dataAccess/skillDataAccess";
import proficiencyDataAccess from "../../../dataAccess/proficiencyDataAccess";
import _ from 'lodash';

export default {
  name: "skillselect",
  data() {
    return {
      colors,
      userEmail: "",
      editableInputErrorMessage: this.inputErrorMessage,
      selectedSkill: "",
      errorMessages: "",
      isLoadingOptions: true,
      skillDAO: new skillDataAccess(),
      proficiencyDAO: new proficiencyDataAccess(),
      fullSkillList: [],
      currentProficiencies: [],
      optionsEmptyText: "Loading skills..."
    };
  },
  props: ["value", "inputErrorMessage", "userEmailToView", "skill"],
  methods: {
    clear() {
      this.selectedSkill = "";
    },
    skillSelected() {
      this.value.skillName = this.selectedSkill;
    },
    async initAllSkills() {
      this.fullSkillList = await this.skillDAO.getAllSkills(
        this.userEmailToView
      );
      this.currentProficiencies = await this.proficiencyDAO.getProficiencies(
        this.userEmailToView
      );
      if(this.skill != "" || this.skill != null || this.skill != undefined){
        this.value.skillName = this.skill;
        this.selectedSkill = this.skill;
      }

      this.isLoadingOptions = false;
    },
    remainingSkills() {
      let resultList = _.filter(this.fullSkillList, skill => {
        var match = _.find(this.currentProficiencies, proficiency => {
          return proficiency.skillName === skill.name;
        });
        return match === undefined;
      });

      if (resultList.length === 0 && !this.isLoadingOptions) {
        this.optionsEmptyText = "No skills remaining";
      } else if(!this.isLoadingOptions){
        this.optionsEmptyText = "No skill found";
      }

      resultList.sort((skill1, skill2) => {
        var skill1Name = skill1.name.toUpperCase();
        var skill2Name = skill2.name.toUpperCase();
        return (skill1Name < skill2Name) ? -1 : (skill1Name > skill2Name) ? 1 : 0;
      });

      return resultList;
    }
  },
  computed: {
    
  },
  mounted: async function() {
    await this.initAllSkills();
  }
};
</script>

<style>
.skill-select-container {
  margin: 20px;
  margin-bottom: 0;
}
</style>
