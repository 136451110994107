<template>
  <div class="add-proficiency-container flex-child">
    <div class="title-bar">
      <h2>Add A Skill Level</h2>
    </div>
    <SkillSelect
      :inputErrorMessage="skillInputErrorMessage"
      :userEmailToView="userEmailToView"
      :skill="selectedSkill"
      :isFilteredList="true"
      v-model="proficiency"
      v-on:skillSelected="onOptionSelected"
      ref="skillSelect"
    />
    <ProficiencySlider
    class="slider-container"
      :proficiency="proficiency"
      :userEmail="userEmailToView"
      :saveOnChange="false"
      :canEdit="true"
      ref="proficiencySlider"
    />
    <v-btn
      class="white--text submit-button"
      :color="colors.PRIMARY"
      v-on:click="onSaveClicked()"
      v-if="!isAddingSkill"
    >Add</v-btn>
    <v-progress-circular
      indeterminate
      v-if="isAddingSkill"
      class="add-skill-progress"
      :color="colors.PRIMARY"
      :size="25"
      :width="3"
    ></v-progress-circular>
    <p class="new-skill-text">
      Not finding the skill you need?
      <span
        class="click-to-add-skill"
        :style="{'color': colors.PRIMARY}"
        v-on:click="showNewSkillDialog"
        v-on:editSkillComplete="addSkillShown = false"
      >Click here</span>
      to create a new skill.
    </p>
    <v-dialog
      v-model="addSkillShown"
      lazy
      :transition="isMobileDialog ? 'dialog-bottom-transition' : ''"
      :fullscreen="isMobileDialog"
      max-width="600px"
    >
      <EditSkill
        v-model="newSkill"
        :isNew="true"
        :canCreateProficiency="true"
        v-on:createSkillComplete="skillCreated"
        v-on:saveError="displayError"
        v-on:closeDialog="addSkillShown = false"
      />
    </v-dialog>
    <v-expansion-panels>
      <v-expansion-panel expand>
        <v-expansion-panel-content>
          <template v-slot:actions>
            <v-icon :color="colors.PRIMARY">vpn_key</v-icon>
          </template>
          <template v-slot:header>
            <h3>Skill Level Key</h3>
          </template>
          <v-card style="font-size:11px; background-color: #fefefe">
            <v-divider />
            <v-card-title>1 - Basic - Baseline understanding of functionality</v-card-title>
            <v-divider />
            <v-card-text>2 - Good - Comfortable consulting internally</v-card-text>
            <v-divider />
            <v-card-text>3 - Proficient - Can speak to it with confidence to clients</v-card-text>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-snackbar
      v-model="snackbarShown"
      :timeout="snackbarTimeout"
      :color="snackbarColor"
    >{{snackbarMessage}}</v-snackbar>
  </div>
</template>

<script>
import colors from "@/colors";
import SkillSelect from "./SkillSelect.vue";
import EditSkill from "../../ManageSkills/components/EditSkill";
import ProficiencySlider from "./ProficiencySlider.vue";
import proficiencyDataAccess from "../../../dataAccess/proficiencyDataAccess";
import tagDataAccess from "../../../dataAccess/tagDataAccess";

export default {
  name: "addproficiency",
  components: {
    SkillSelect,
    ProficiencySlider,
    EditSkill
  },
  data() {
    return {
      colors,
      proficiency: {
        id: 0,
        skillName: this.selectedSkill,
        level: 1,
        email: this.userEmailToView
      },
      skillInputErrorMessage: "",
      snackbarShown: false,
      snackbarTimeout: 3000,
      snackbarColor: "",
      snackbarMessage: "",
      proficiencyDAO: new proficiencyDataAccess(),
      tagDAO: new tagDataAccess(),
      newSkill: {},
      addSkillShown: false,
      isMobileDialog: window.innerWidth < 900,
      tagOptions: [],
      isAddingSkill: false
    };
  },
  props: ["userEmailToView", "selectedSkill"],
  methods: {
    async onSaveClicked() {
      if (
        this.proficiency.skillName === undefined ||
        this.proficiency.skillName === ""
      ) {
        this.skillInputErrorMessage = "Select a skill name";
        return;
      } else {
        this.skillInputErrorMessage = "";
      }

      this.isAddingSkill = true;

      this.proficiency.email = this.userEmailToView;

      try {
        await this.proficiencyDAO.postProficiency(this.proficiency);
      } catch (err) {
        this.isAddingSkill = false;
        return;
      }

      this.proficiency.id = 0;
      this.proficiency.skillName = "";
      this.proficiency.level = "";

      this.$refs.skillSelect.clear();
      this.$refs.proficiencySlider.clear();
      this.snackbarMessage = "Success: Added Skill";
      this.snackbarColor = "green";
      this.snackbarShown = true;

      this.isAddingSkill = false;
    },
    onOptionSelected(event) {
      this.proficiency.skillName = event;
      this.skillInputErrorMessage = "";
    },
    displayError(error) {
      this.snackbarMessage = "Error: " + error;
      this.snackbarColor = "red";
      this.snackbarShown = true;
    },
    showNewSkillDialog() {
      this.newSkill = {
        id: 0,
        name: "",
        category: {
          id: 0,
          name: ""
        },
        tags: []
      };
      this.addSkillShown = true;
    },
    skillCreated() {
      this.snackbarMessage = "Success: Skill Level created.";
      this.snackbarColor = "green";
      this.snackbarShown = true;
      this.proficiency.skillName = this.newSkill.name;
      this.addSkillShown = false;
    }
  }
};
</script>

<style scoped>
.add-proficiency-container {
  color: #444444;
  box-shadow: 0px 1px 5px #aaaaaa;
  max-width: 320px;
  min-width: 320px;
}

.submit-button {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  margin-bottom: 25px;
}

.new-skill-text {
  margin: 10px;
  margin-top: 0px;
  color: #777777;
}

.click-to-add-skill {
  font-weight: 600;
  cursor: pointer;
}

.add-skill-progress{
  margin-bottom: 33px;
  margin-top: 9px;
}

.slider-container{
  margin-left: -10px;
  background-color: transparent;
}

@media screen and (max-width: 796px) {
  .add-skill-container {
    order: -1;
  }
}
</style>