<template>
  <div class="myskills">
    <div class="title-bar">
      <h1 v-if="isViewingSelf === true">My Skills</h1>
      <h1 v-else-if="userViewing !== null">{{ userViewing.name }}'s Skills</h1>
    </div>
    <div class="action-bar pa-2" v-if="userViewing != null">
      <v-btn text color="primary" @click="viewCerts()" class="mr-3">
        <v-icon left>account_balance</v-icon>
        View Certifications
      </v-btn>

      <v-btn text color="primary" @click="reviewSkills()" v-if="canEdit && staleProficiencyCount !== 0" class="mr-3">
        <v-icon left>assignment_turned_in</v-icon>
        Review Stale Skills ({{staleProficiencyCount}})
      </v-btn>

      <v-btn text color="primary" @click="downloadResume()" :loading="isLoadingResume" class="mr-3">
        <v-icon left>cloud_download</v-icon>
        Download Resume
      </v-btn>

      <v-btn text color="primary" @click="emailResume()" :loading="isLoadingResume">
        <v-icon left>email</v-icon>
        Email Resume
      </v-btn>      
    </div>
    <div class="skills-content">
      <AddProficiency
        v-if="canEdit"
        :userEmailToView="selectedUserEmail"
        :selectedSkill="selectedSkill"
      />
      <UserProficiencyList
        v-if="isViewingSelf || userViewing !== null"
        :canEdit="canEdit"
        :userEmailToView="selectedUserEmail"
        :selectedProficiency="selectedProficiency"
      />
      <div class="skill-survey flex-child" v-if="canEdit">
        <div class="title-bar subsection">
          <h2>Skill Survey</h2>
        </div>
        <div class="skill-survey-container">
          <v-btn
            class="white--text submit-button"
            :color="colors.PRIMARY"
            v-on:click="goToSkillSurvey()"
          >Skills Survey</v-btn>
          <p>Just starting out? Fill out a survey of all your skills to add your skills fast!</p>
        </div>
      </div>
    </div>
    <v-snackbar
      v-model="snackbarShown"
      :timeout="snackbarTimeout"
      :color="'red'"
    >{{snackbarMessage}}</v-snackbar>
  </div>
</template>

<script>
import colors from "@/colors";
import UserProficiencyList from "./components/UserProficiencyList.vue";
import AddProficiency from "./components/AddProficiency";
import roleIdentifier from "@/roleIdentifier";
import userDataAccess from "../../dataAccess/userDataAccess";
import resumeDataAccess from "@/dataAccess/resumeDataAccess";
import proficiencyDataAccess from '../../dataAccess/proficiencyDataAccess';

export default {
  name: "skills",
  data() {
    return {
      colors,
      userViewing: null,
      isViewingSelf: false,
      userDAO: new userDataAccess(),
      proficiencyDAO: new proficiencyDataAccess(),
      resumeDAO: new resumeDataAccess(),
      snackbarShown: false,
      snackbarMessage: "",
      snackbarTimeout: 3000,
      isLoadingResume: false,
      staleProficiencyCount: 0
    };
  },
  components: {
    UserProficiencyList,
    AddProficiency
  },
  computed: {
    canEdit() {
      if (this.isViewingSelf) {
        return true;
      }
      if (roleIdentifier.amIAdmin()) {
        return true;
      }

      return false;
    },
    selectedUser() {
      return this.$route.query.user;
    },
    selectedSkill() {
      return this.$route.query.skill;
    },
    selectedProficiency() {
      return this.$route.query.proficiency;
    },
    selectedUserEmail() {
      if (this.userViewing === null) {
        return "";
      }
      return this.selectedUser;
    }
  },
  mounted: async function() {
    await this.initView();
  },
  methods: {
    async initView() {
      let response = "";
      try {
        response = await this.userDAO.getAccount();
        if (
          this.selectedUser === undefined ||
          this.selectedUser === "" ||
          this.selectedUser == response.email
        ) {
          this.isViewingSelf = true;
          this.userViewing = response;
        } else {
          this.isViewingSelf = false;
          try {
            response = await this.userDAO.getAccount(this.selectedUser);
            this.userViewing = response;
          } catch (err) {
            console.log(err);
            return;
          }
        }
      } catch (err) {
        console.log(err);
        return;
      }

      let proficiencies = await this.proficiencyDAO.getProficiencies(this.selectedUser);
      this.staleProficiencyCount = proficiencies.filter(prof => {
        return new Date().getTime() - new Date(prof.reviewedDate).getTime() > 7776000000; //7776000000 is the amount of ticks in 90 days
      }).length;
    },
    goToSkillSurvey() {
      if (this.isViewingSelf) {
        this.$router.push("/skillsurvey");
      } else {
        this.$router.push("/skillsurvey?user=" + this.userViewing.email);
      }
    },
    reviewSkills(){
      if (this.isViewingSelf) {
        this.$router.push("/skillreview");
      } else {
        this.$router.push("/skillreview?user=" + this.userViewing.email);
      }
    },
    viewCerts(){
      if(this.isViewingSelf){
        this.$router.push("/certifications");
      } else{
        this.$router.push(`certifications?userId=${this.userViewing.email}`);
      }
    },
    async downloadResume() {
      this.isLoadingResume = true;
      let downloadLink = "";
      try {
        downloadLink = await this.resumeDAO.getResumeDownloadLink(
          this.userViewing.name
        );
      } catch (ex) {
        if (ex.response.status === 404) {
          this.showResumeNotFoundError(this.userViewing.name);
        } 
        else if (ex.response.status === 500) {
          this.showResumeNotFoundError(this.userViewing.name);
        } else {
          this.showResumeRetrievalError();
        }
        this.isLoadingResume = false;
        return;
      }

      window.location.href = downloadLink;
      this.isLoadingResume = false;
    },
    async emailResume() {
      this.isLoadingResume = true;
      let resumeLink = "";
      try {
        resumeLink = await this.resumeDAO.getResumeSharingLink(
          this.userViewing.name
        );
      } catch (ex) {
        if (ex.response.status === 404) {
          this.showResumeNotFoundError(this.userViewing.name);
        } 
        else if (ex.response.status === 500) {
          this.showResumeNotFoundError(this.userViewing.name);
        } else {
          this.showResumeRetrievalError();
        }
        this.isLoadingResume = false;
        return;
      }

      location.href = `mailto:?&subject=${
        this.userViewing.name
      } Omnitech Resume&body=${this.getResumeEmailBody(
        this.userViewing.name,
        resumeLink
      )}`;

      this.isLoadingResume = false;
    },
    getResumeEmailBody(engineerName, resumeLink) {
      let resultString = `Visit the link below to view ${engineerName}'s resume\n\n\n`;
      resultString += `${resumeLink}\n\n\n`;
      resultString +=
        "NOTE: This access link will expire 14 days after receiving this email.\n\n\n";
      return encodeURIComponent(resultString);
    },
    showResumeNotFoundError(engineerName) {
      this.snackbarTimeout = 3000;
      this.snackbarMessage = `Could not find a resume for ${engineerName}`;
      this.snackbarShown = true;
    },
    showResumeRetrievalError() {
      this.snackbarTimeout = 3000;
      this.snackbarMessage = "Error retrieving resume";
      this.snackbarShown = true;
    }
  }
};
</script>

<style scoped>
.title-bar {
  position: relative;
  z-index: 0;
}

.action-bar {
  display: flex;
  flex-direction: row;  
  background-color: white;
  box-shadow: 0px 0px 5px #999999;  
}

.hidden {
  display: none;
}

.user-context-container {
  margin-top: -3px;
}

.skills-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-child {
  margin: auto 20px;
  margin-top: 20px;
  flex-direction: column;
  flex: 1 1 350px;
}

.skill-survey-container {
  margin: 20px;
  margin-bottom: 0;
}

.subsection{
  position:inherit;
}

.skill-survey {
  color: #444444;
  box-shadow: 0px 1px 5px #aaaaaa;
  max-width: 370px;
  margin: auto 20px;
  margin-top: 20px;
  flex-direction: column;
  flex: 1 1 200px;
  text-align: left;
  font-size: 16px;
  padding-bottom: 10px;
  background-color: #fefefe;
}
.submit-button {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
  margin-bottom: 25px;
}

.spacer {
  width: 10px;
}

.loading-resume-indicator{
  margin-top: 10px;
  margin-bottom: 13px;
  margin-left: 20px;
}

@media screen and (max-width: 796px) {
  .flex-child {
    margin: 20px 0;
    max-width: 1000px !important;
    width: 100%;
    flex: 1 1 1000px;
  }
}

@media screen and (max-width: 650px){
  .action-bar{
    flex-direction: column;
  }
}
</style>