<template>
  <div class="user-proficiencies-container flex-child">
    <div class="title-bar title-section">
      <h2>{{titleMessage}}</h2>
      <v-text-field
        :color="colors.PRIMARY"
        class="skill-search-box"
        v-model="searchValue"
        :label="'Search'"
        :loading="isLoadingSkills"
      ></v-text-field>
      <v-btn icon v-if="sortMode" @click="toggleSortDirection()">
        <v-icon v-if="sortDirection === sortingConstants.DESCENDING">keyboard_arrow_up</v-icon>
        <v-icon v-if="sortDirection === sortingConstants.ASCENDING">keyboard_arrow_down</v-icon>
      </v-btn>
      <v-menu right>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon :color="colors.PRIMARY">sort</v-icon>
          </v-btn>
        </template>
        <v-list class="menu-options">
          <v-list-item @click="sortMode=sortingConstants.REVIEWED_DATE">
            <v-icon class="action-icon" :color="colors.PRIMARY">access_time</v-icon>
            <div class="spacer"></div>
            <v-list-item-title>Sort By Date Reviewed</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sortMode=sortingConstants.MODIFIED_DATE">
            <v-icon class="action-icon" :color="colors.PRIMARY">timer</v-icon>
            <div class="spacer"></div>
            <v-list-item-title>Sort By Date Modified</v-list-item-title>
          </v-list-item>
          <v-list-item @click="sortMode=sortingConstants.ALPHABETICAL">
            <v-icon class="action-icon" :color="colors.PRIMARY">sort_by_alpha</v-icon>
            <div class="spacer"></div>
            <v-list-item-title>Sort Alphabetically</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="sortMode=''; sortDirection=sortingConstants.ASCENDING"
            v-if="sortMode"
          >
            <v-icon class="action-icon" :color="colors.PRIMARY">cancel</v-icon>
            <div class="spacer"></div>
            <v-list-item-title>Clear Sort</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-expansion-panels v-if="!sortMode" multiple v-model="expandedPanels">
      <v-expansion-panel v-for="group in currentGroupedProficiencies" :key="group[0].category">
        <v-expansion-panel-header>
          <h2>{{group[0].category}}</h2>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <template v-slot:actions>
            <v-icon :color="colors.PRIMARY">$vuetify.icons.expand</v-icon>
          </template>
          
          <v-card v-for="proficiency in group" :key="proficiency.id">
            <v-divider></v-divider>
            <ProficiencySlider :canEdit="canEdit" :proficiency="proficiency" :saveOnChange="true" />
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>


    <div v-if="sortMode">
      <v-card v-for="proficiency in currentSortedProficiencies" :key="proficiency.id">
        <v-divider></v-divider>
        <ProficiencySlider :canEdit="canEdit" :proficiency="proficiency" :saveOnChange="true" />
      </v-card>
    </div>
  </div>
</template>

<script>
import colors from "@/colors";
import _ from 'lodash';
import ProficiencySlider from "./ProficiencySlider.vue";
import proficiencyDataAccess from "../../../dataAccess/proficiencyDataAccess";
import sortingConstants from "@/sortingConstants";

export default {
  name: "userproficiencylist",
  data() {
    return {
      colors,
      proficiencyDAO: new proficiencyDataAccess(),
      currentProficiencies: [],
      expandedPanels: [],
      searchValue: this.selectedProficiency,
      isLoadingSkills: true,
      sortMode: "",
      sortingConstants: sortingConstants,
      sortDirection: sortingConstants.ASCENDING
    };
  },
  components: {
    ProficiencySlider
  },
  props: ["userEmailToView", "canEdit", "selectedProficiency"],
  mounted: async function() {
    // this.sortMode = sortingConstants.ALPHABETICAL;
    await this.initializeProficiencyLists();
  },
  methods: {
    async initializeProficiencyLists() {
      try {
        this.currentProficiencies = await this.proficiencyDAO.getProficiencies(this.userEmailToView);
        this.expandedPanels = this.currentProficiencies.map(function() {
          return true;
        });
        this.isLoadingSkills = false;
      } catch (err) {
        console.log(err);
        return;
      }
    },
    getFilteredProficiencies() {
      if (!this.searchValue) {
        return this.currentProficiencies;
      }
      return _.filter(this.currentProficiencies, proficiency => {
        return proficiency.skillName
          .toUpperCase()
          .includes(this.searchValue.toUpperCase());
      });
    },
    
    toggleSortDirection() {
      this.sortDirection = this.sortDirection === sortingConstants.ASCENDING ? sortingConstants.DESCENDING : sortingConstants.ASCENDING;
    },
    sortByReviewDateAscending(proficienciesTmp){
      return proficienciesTmp.sort((prof1, prof2) => {
        return new Date(prof1.reviewedDate) - new Date(prof2.reviewedDate);
      });
    },
    sortByReviewDateDescending(proficienciesTmp){
      return proficienciesTmp.sort((prof1, prof2) => {
        return new Date(prof2.reviewedDate) - new Date(prof1.reviewedDate);
      });
    },
    sortByModifiedDateAscending(proficienciesTmp){
      return proficienciesTmp.sort((prof1, prof2) => {
        return new Date(prof1.modifiedDate) - new Date(prof2.modifiedDate);
      });
    },
    sortByModifiedDateDescending(proficienciesTmp){
      return proficienciesTmp.sort((prof1, prof2) => {
        return new Date(prof2.modifiedDate) - new Date(prof1.modifiedDate);
      });
    },
    sortByAlphabeticalAscending(proficienciesTmp){
      return proficienciesTmp.sort((prof1, prof2) => {
        if (prof1.skillName.toUpperCase() < prof2.skillName.toUpperCase())
          return -1;
        if (prof1.skillName.toUpperCase() > prof2.skillName.toUpperCase())
          return 1;
        else return 0;
      });
    },
    sortByAlphabeticalDescending(proficienciesTmp){
      return proficienciesTmp.sort((prof1, prof2) => {
        if (prof1.skillName.toUpperCase() > prof2.skillName.toUpperCase())
          return -1;
        if (prof1.skillName.toUpperCase() < prof2.skillName.toUpperCase())
          return 1;
        else return 0;
      });
    }
  },
  computed: {
    currentGroupedProficiencies() {
      if (this.currentProficiencies.length === 0) {
        return [];
      }

      let filteredProficiencies = this.getFilteredProficiencies();
      let sortedProficiencies = _.sortBy(filteredProficiencies, [
        "category",
        "skillName"
      ]);
      return _.groupBy(sortedProficiencies, "category");
    },
    currentSortedProficiencies() {
      if (!this.sortMode) {
        return;
      }

      var proficienciesTmp = this.getFilteredProficiencies();

      if (this.sortDirection === sortingConstants.ASCENDING) {
        if (this.sortMode === sortingConstants.REVIEWED_DATE) {
          return this.sortByReviewDateAscending(proficienciesTmp);
        } 
        else if (this.sortMode === sortingConstants.MODIFIED_DATE) {
          return this.sortByModifiedDateAscending(proficienciesTmp);
        } 
        else if (this.sortMode === sortingConstants.ALPHABETICAL) {
          return this.sortByAlphabeticalAscending(proficienciesTmp);
        }
      } else {
        if (this.sortMode === sortingConstants.REVIEWED_DATE) {
          return this.sortByReviewDateDescending(proficienciesTmp);
        } 
        else if (this.sortMode === sortingConstants.MODIFIED_DATE) {
          return this.sortByModifiedDateDescending(proficienciesTmp);
        } 
        else if (this.sortMode === sortingConstants.ALPHABETICAL) {
          return this.sortByAlphabeticalDescending(proficienciesTmp);
        }
      }

      return [...this.currentProficiencies];
    },
    titleMessage() {
      if (this.isLoadingSkills) {
        return "Loading Skills";
      } else if (this.currentProficiencies.length === 0) {
        return "No Skills Yet!";
      } else {
        return "Skills";
      }
    }
  },
  watch: {
    currentGroupedProficiencies() {
      this.expandedPanels = this.currentProficiencies.map(function() {
        return true;
      });      
    }
  }
};
</script>

<style scoped>
.user-proficiencies-container {
  color: #444444;
  box-shadow: 0px 1px 5px #aaaaaa;
  max-width: 600px;
  background-color: #fefefe;
  min-width: 350px;
}

.title-section {
  display: flex;
  align-items: center;
}

.skill-search-box {
  margin: 0 20px;
  margin-bottom: -5px;
}

.spacer {
  width: 15px;
}

.menu-options {
  position: relative;
  z-index: 10;
}

.hidden {
  display: none;
}
</style>