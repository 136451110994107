const ASCENDING = "ascending";
const DESCENDING = "descending";

const MODIFIED_DATE = "date-modified";
const REVIEWED_DATE = "date-reviewed";
const ALPHABETICAL = "alphabetical";

class sortingConstants{
  static get ASCENDING() { return ASCENDING }
  static get DESCENDING() { return DESCENDING } 

  static get MODIFIED_DATE() { return MODIFIED_DATE }
  static get REVIEWED_DATE() { return REVIEWED_DATE }
  static get ALPHABETICAL() { return ALPHABETICAL }

}

export default sortingConstants;